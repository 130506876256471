import React, {useEffect, useState} from 'react';
import Select, {Option} from '../select/select';

export interface IPaginator {
  /**
   * Number of pages existing
   */
  pages: number;
  /**
   * First page to be shown
   */
  defaultPage?: number;
  /**
   * Selector for the collapsed Variant
   */
  collapsed?: boolean;
  className?: string;
  disabled?: boolean;
  onChangePage?: (page: number) => void;
  [key: string]: any;
}

const Paginator: React.FC<IPaginator> = (props) => {
  const {pages, defaultPage, collapsed, disabled, className, onChangePage, ...rest} = props;
  const [current, setCurrent] = useState<number>(defaultPage ?? 1);
  useEffect(() => {
    if (defaultPage && defaultPage !== current) {
      setCurrent(defaultPage);
    }
  }, [defaultPage]);

  const getNumbers = () => {
    const _numbers: any[] = [];
    if (_splitArr.length) {
      const _cIndex = _splitArr.findIndex((page) => page === current);
      let _first = 0;
      if (_cIndex !== -1)
        if (_cIndex < 3) {
          _first = 0;
        } else if (_cIndex > _splitArr.length - 4) {
          _first = _splitArr.length - 4;
        } else if (_splitArr.length - _cIndex < 4) {
          _first = _cIndex - (_splitArr.length - _cIndex);
        } else {
          _first = _cIndex - 1;
        }
      else {
        if (arr[0] === current) _first = 0;
        else if (arr[arr.length - 1] === current) _first = _splitArr.length - 4;
      }
      if (_first < 0) _first = 0;
      for (let n = _first; n < _first + 4 && n < _splitArr.length; n++) {
        _numbers.push(_splitArr[n]);
      }
    }
    return _numbers;
  };

  const arr = pages ? Array.from({length: pages}, (_, idx) => idx + 1) : [];
  const _splitArr = arr.slice(1, arr.length - 1);

  const onChange = (page: number) => {
    setCurrent(page);

    if (typeof onChangePage === 'function') onChangePage(page);
  };

  if (arr && arr.length) {
    if (collapsed) {
      return (
        <div
          className={`paginator-collapsed ${className ?? ''}`}
          data-testid={rest && rest['data-testid'] ? rest['data-testid'] : undefined}
        >
          {
            <button
              type="button"
              disabled={disabled || current === arr[0]}
              className="paginator-icon"
              data-testid={rest && rest['data-testid'] ? `${rest['data-testid']}-button-jump-previous` : undefined}
              onClick={() => onChange(arr[0])}
            >
              <span className="material-icons">first_page</span>
            </button>
          }
          {arr.length !== 0 && (
            <button
              type="button"
              disabled={disabled || current === arr[0]}
              className="paginator-icon"
              data-testid={rest && rest['data-testid'] ? `${rest['data-testid']}-button-previous` : undefined}
              onClick={() => current !== arr[0] && onChange(current - 1)}
            >
              <span className="material-icons">navigate_before</span>
            </button>
          )}
          <Select
            disabled={disabled}
            name="paginator-selector"
            placeholder={defaultPage?.toString() || '1'}
            className="paginator-collapsed-dropdown"
            value={current}
            data-testid={rest && rest['data-testid'] ? `${rest['data-testid']}-dropdown` : undefined}
            onChange={onChange}
          >
            {arr &&
              arr.length > 0 &&
              arr.map((num) => (
                <Option
                  key={num + 'select-item'}
                  value={num}
                  label={num.toString()}
                  data-testid={rest && rest['data-testid'] ? `${rest['data-testid']}-dropdown-item-${num}` : undefined}
                >
                  {num}
                </Option>
              ))}
          </Select>
          <span>of {arr.length} pages</span>
          {arr.length !== 0 && (
            <button
              type="button"
              disabled={disabled || current === arr[arr.length - 1]}
              className="paginator-icon"
              data-testid={rest && rest['data-testid'] ? `${rest['data-testid']}-button-next` : undefined}
              onClick={() => current !== arr[arr.length - 1] && onChange(current + 1)}
            >
              <span className="material-icons">navigate_next</span>
            </button>
          )}
          {
            <button
              type="button"
              disabled={disabled || current === arr[arr.length - 1]}
              className="paginator-icon"
              data-testid={rest && rest['data-testid'] ? `${rest['data-testid']}-button-jump-next` : undefined}
              onClick={() => onChange(arr[arr.length - 1])}
            >
              <span className="material-icons">last_page</span>
            </button>
          }
        </div>
      );
    }

    const _numbersShown = getNumbers();

    return (
      <div
        className={`paginator ${className ?? ''}`}
        data-testid={rest && rest['data-testid'] ? rest['data-testid'] : undefined}
      >
        <button
          type="button"
          disabled={disabled || current === arr[0]}
          className="paginator-icon"
          onClick={() => onChange(current - 1)}
          data-testid={rest && rest['data-testid'] ? `${rest['data-testid']}-button-previous` : undefined}
        >
          <span className="material-icons">navigate_before</span>
        </button>
        <button
          type="button"
          disabled={disabled}
          className={current === arr[0] ? 'paginator-page_active' : 'paginator-page'}
          data-testid={rest && rest['data-testid'] ? `${rest['data-testid']}-truncate-left` : undefined}
          onClick={() => onChange(arr[0])}
        >
          {arr[0]}
        </button>
        {_numbersShown[0] !== _splitArr[0] && (
          <>
            <span className="paginator-truncate">...</span>
          </>
        )}
        {_numbersShown.map((_num) => (
          <button
            type="button"
            disabled={disabled}
            className={current === _num ? 'paginator-page_active' : 'paginator-page'}
            onClick={() => onChange(_num)}
            key={_num + 'paginator-num-page'}
            data-testid={rest && rest['data-testid'] ? `${rest['data-testid']}-button-num-${_num}` : undefined}
          >
            {_num}
          </button>
        ))}
        {_numbersShown[_numbersShown.length - 1] !== _splitArr[_splitArr.length - 1] && (
          <>
            <span className="paginator-truncate">...</span>
          </>
        )}
        {arr.length > 1 && (
          <button
            type="button"
            disabled={disabled}
            className={current === arr[arr.length - 1] ? 'paginator-page_active' : 'paginator-page'}
            data-testid={rest && rest['data-testid'] ? `${rest['data-testid']}-truncate-right` : undefined}
            onClick={() => onChange(arr[arr.length - 1])}
          >
            {arr[arr.length - 1]}
          </button>
        )}

        <button
          type="button"
          disabled={disabled || current === arr.length}
          className="paginator-icon"
          onClick={() => onChange(current + 1)}
          data-testid={rest && rest['data-testid'] ? `${rest['data-testid']}-button-next` : undefined}
        >
          <span className="material-icons">navigate_next</span>
        </button>
      </div>
    );
  }
  return null;
};

export default Paginator;
