import React, {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';

export {default as NotificationHeader} from './notificationHeader';
export {default as NotificationBody} from './notificationBody';
export {default as NotificationFooter} from './notificationFooter';

interface INotification {
  /**
   * Value of icon that will show in header
   */
  icon?: any;
  /**
   * Additional styling if needed
   */
  className?: string;
  /**
   * Change status to Success
   */
  success?: boolean;
  /**
   * Change status to Error
   */
  error?: boolean;
  /**
   * Change status to Info
   */
  info?: boolean;
  /**
   * Change status to Warning
   */
  warning?: boolean;
  /**
   * Change status to Read
   */
  read?: boolean;
  /**
   * Handler to show or not as a portal, fixed on top of screen
   */
  renderAsPortal?: boolean;
  /**
   * State for showing the notification when render as portal
   */
  show?: boolean;
  /**
   * handler function for the close button when render as portal
   */
  onClose?: () => void;
  /**
   * Number in miliseconds to close automaticly the notification when render as portal
   */
  autoclose?: number;
  [others: string]: any;
}

const Notification: React.FC<INotification> = ({
  className,
  renderAsPortal,
  icon,
  children,
  onClose,
  success,
  error,
  warning,
  info,
  read,
  show,
  autoclose,
  ...rest
}) => {
  const iconTestId = rest && rest['data-testid'] ? rest['data-testid'] + '-close-test' : undefined;
  const closeTimer: any = useRef(null);
  const state = () => {
    if (success) return 'success';
    if (error) return 'error';
    if (info) return 'info';
    if (warning) return 'warning';
    if (read) return 'read';
    return 'read';
  };

  useEffect(() => {
    if (show && renderAsPortal && onClose && autoclose) {
      clearTimeout(closeTimer.current);
      closeTimer.current = setTimeout(() => {
        onClose();
      }, autoclose);
    }

    return () => clearTimeout(closeTimer.current);
  }, [show]);

  const IconClose = () => (
    <button type="button" className="notification-close-button" onClick={onClose} data-testid={iconTestId || undefined}>
      <span className="material-icons">close</span>
    </button>
  );

  const content = (
    <div
      className={`${renderAsPortal ? 'tag-ds' : ''} notification_${state()} ${className || ''}`}
      {...rest}
      data-portal={renderAsPortal}
    >
      {typeof onClose === 'function' && <IconClose />}
      {icon ? (
        <>
          <div className="notification-icon">{icon}</div>
          <div className="notification-content">{children}</div>
        </>
      ) : (
        children
      )}
    </div>
  );

  if (renderAsPortal && typeof document !== 'undefined') {
    const container = document.getElementById('root') || document.body;
    return show ? ReactDOM.createPortal(content, container as Element) : null;
  }

  return content;
};

export default Notification;
