import React from 'react';
import {useAccordion} from './accordion';

export interface IAccordionContentProps {
  /**
   * Add class to accordion content
   */
  className?: string;
  [others: string]: any;
}

const AccordionContent: React.FC<IAccordionContentProps> = ({children, className, ...rest}) => {
  const {id, showContent} = useAccordion();

  return (
    <div
      className={`accordion-content ${className || ''}`}
      {...rest}
      id={`${id}-content`}
      role="region"
      aria-labelledby={`${id}-header`}
      data-show={showContent}
    >
      <div className="accordion-content_inner">{children}</div>
    </div>
  );
};

export default AccordionContent;
