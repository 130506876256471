import React, {useEffect, useState} from 'react';
import {useDropdown} from './dropdown';

export interface DropdownMenuProps {
  className?: string;
  [others: string]: any;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({className, children, ...rest}) => {
  const ref = React.useRef<HTMLUListElement>(null);
  const {id, showMenu, dropdownRef, handleClickMenu, keepShown, placement} = useDropdown();
  const [position, setPosition] = useState(placement);

  useEffect(() => {
    if (showMenu) {
      document.addEventListener(
        'scroll',
        () => {
          setPosition(getPosition());
        },
        true
      );
      return () =>
        document.removeEventListener('scroll', () => {
          setPosition(getPosition());
        });
    }
  }, [showMenu, ref, dropdownRef]);

  useEffect(() => {
    setPosition(getPosition());
  }, [showMenu, ref, dropdownRef]);

  const getPosition = () => {
    if (dropdownRef && dropdownRef.current && ref && ref.current && showMenu) {
      const position = dropdownRef.current.getBoundingClientRect();
      const dropdownMenuDim = ref.current.getBoundingClientRect();
      const {clientHeight, clientWidth} = document.body;

      switch (placement) {
        case 'top':
          if (position.top - dropdownMenuDim.height - 8 < 0) {
            return 'bottom';
          }
          return 'top';

        case 'right':
          if (position.left + position.width + dropdownMenuDim.width + 8 > clientWidth) {
            return 'left';
          }
          return 'right';

        case 'bottom':
          if (position.top + position.height + dropdownMenuDim.height + 8 > clientHeight) {
            return 'top';
          }
          return 'bottom';

        case 'left':
          if (position.left - dropdownMenuDim.width - 8 < 0) {
            return 'right';
          }
          return 'left';
      }
    }
  };

  const alignment = () => {
    if (dropdownRef && dropdownRef.current && ref && ref.current && showMenu) {
      const position = dropdownRef.current.getBoundingClientRect();
      const dropdownMenuDim = ref.current.getBoundingClientRect();
      const {clientHeight, clientWidth} = document.body;

      switch (placement) {
        case 'top':
          if (position.left + dropdownMenuDim.width > clientWidth) {
            return 'right';
          }
          return 'left';

        case 'right':
          if (position.top + dropdownMenuDim.height > clientHeight) {
            return 'bottom';
          }
          return 'top';

        case 'bottom':
          if (position.left + dropdownMenuDim.width > clientWidth) {
            return 'right';
          }
          return 'left';

        case 'left':
          if (position.top + dropdownMenuDim.height > clientHeight) {
            return 'bottom';
          }
          return 'top';
      }
    }
  };

  const content = (
    <ul
      id={`${id || 'dropdown'}-menu`}
      ref={ref}
      className={`dropdown-menu ${position} ${showMenu ? '' : 'hidden'} ${className || ''}`}
      data-alignment={alignment()}
      aria-labelledby={`${id}-btn`}
      onClick={!keepShown ? handleClickMenu : undefined}
      {...rest}
    >
      {children}
    </ul>
  );

  return content;
};

export default DropdownMenu;
