import React from 'react';
import {useDropdown} from './dropdown';
export interface DropdownButtonProps {
  className?: string;
  disabled?: boolean;
  [others: string]: any;
}

const DropdownButton: React.FC<DropdownButtonProps> = ({children, className, disabled, ...rest}) => {
  const {id, showMenu, onToggleMenu} = useDropdown();
  const onKeyDown = (e: React.KeyboardEvent) => {
    const code = e.code;
    if (code === 'Space' || code === 'Enter') onToggleMenu();
  };

  return (
    <div
      role="button"
      id={`${id}-btn`}
      className={`dropdown-button ${disabled ? 'disabled' : ''}${className || ''}`}
      onClick={onToggleMenu}
      aria-expanded={showMenu}
      data-toggle="dropdown"
      {...rest}
      tabIndex={0}
      onKeyDown={onKeyDown}
    >
      {children}
    </div>
  );
};

export default DropdownButton;
