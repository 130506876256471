import React, {useContext, useEffect, useRef, useState} from 'react';

export {default as DropdownButton} from './dropdownButton';
export {default as DropdownMenu} from './dropdownMenu';
const DropdownContext = React.createContext({});
type DropdownPosition = 'top' | 'bottom' | 'right' | 'left';
interface DropdownProps {
  id: string;
  /**
   * Add additional styles
   */
  className?: string;
  /**
   * Control toggle menu out of component
   */
  onChangeToggleMenu?: (state: boolean) => void;
  /**
   * Refresh data of menu
   */
  forceRefresh?: number;
  /**
   * Default state of menu
   */
  defaultShow?: boolean;
  /**
   * Keep showing when click on menu item, default is close when click on item
   */
  keepShown?: boolean;
  /**
   * Position of dropdown menu
   */
  placement?: DropdownPosition;
  [others: string]: any;
}

interface IDropdownContext {
  id: string;
  showMenu: boolean;
  setDropdownMenuDimensions: (dimensions: {width: number; height: number}) => void;
  setDropdownButtonDimensions: (dimensions: {width: number; height: number}) => void;
  handleClickMenu: (e: React.MouseEvent<HTMLUListElement>) => void;
  position: {left: string; top: string};
  dropdownRef: React.RefObject<HTMLDivElement>;
  onToggleMenu: () => void;
  keepShown: boolean;
  placement?: DropdownPosition;
}

const Dropdown: React.FC<DropdownProps> = ({
  id,
  children,
  className,
  onChangeToggleMenu,
  forceRefresh,
  defaultShow,
  keepShown = false,
  placement = 'bottom',
  ...rest
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [refresh, setRefresh] = useState(forceRefresh ? forceRefresh : 0);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const onToggleMenu = () => {
    if (typeof onChangeToggleMenu === 'function') onChangeToggleMenu(!showMenu);
    setShowMenu((prev) => {
      return !prev;
    });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (event && event.target) {
      if (
        dropdownRef &&
        dropdownRef.current &&
        dropdownRef.current.getAttribute('data-show') === 'true' &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowMenu(false);
        if (typeof onChangeToggleMenu === 'function') onChangeToggleMenu(false);
      }
    }
  };

  const handleClickMenu = () => {
    if (!keepShown) {
      setShowMenu(false);
      if (typeof onChangeToggleMenu === 'function') onChangeToggleMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  useEffect(() => {
    if (defaultShow !== undefined && defaultShow !== showMenu) {
      setShowMenu(defaultShow as boolean);
    }
  }, [defaultShow]);

  useEffect(() => {
    if (forceRefresh && refresh !== forceRefresh) setRefresh(forceRefresh);
  }, [forceRefresh, refresh]);

  const onKeyDown = (e: React.KeyboardEvent) => {
    const code = e.code;
    const focusElement = document?.activeElement;
    const _button = document?.getElementById(`${id}-btn`);
    const _menu = document?.getElementById(`${id}-menu`);

    const _focusItems = _menu?.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');

    switch (code) {
      case 'ArrowDown':
        if (!showMenu) {
          e.preventDefault();
          e.stopPropagation();
          onToggleMenu();
        } else {
          if (focusElement && _focusItems) {
            e.preventDefault();
            e.stopPropagation();
            if (focusElement.id === _button?.id) {
              (_focusItems[0] as HTMLElement).focus();
            } else {
              let _idx = -1;
              _focusItems.forEach((_item, key) => {
                if (_item.isSameNode(focusElement)) {
                  _idx = key + 1;
                }
              });

              if (_idx >= 0 && _idx < _focusItems.length) (_focusItems[_idx] as HTMLElement).focus();
              else if (_idx >= _focusItems.length && _button) _button.focus();
            }
          }
        }

        break;
      case 'ArrowUp':
        if (!showMenu) {
          e.preventDefault();
          e.stopPropagation();
          onToggleMenu();
        } else {
          if (focusElement && _focusItems) {
            e.preventDefault();
            e.stopPropagation();
            if (focusElement.id === _button?.id) {
              (_focusItems[_focusItems.length - 1] as HTMLElement).focus();
            } else {
              let _idx = -1;
              _focusItems.forEach((_item, key) => {
                if (_item.isSameNode(focusElement)) {
                  _idx = key + -1;
                }
              });

              if (_idx >= 0 && _idx < _focusItems.length) (_focusItems[_idx] as HTMLElement).focus();
              else if (_button) _button.focus();
            }
          }
        }
        break;

      case 'Escape':
        if (showMenu) onToggleMenu();
        break;
    }
  };

  return (
    <DropdownContext.Provider
      value={{
        id,
        showMenu,
        handleClickMenu,
        dropdownRef,
        onChangeToggleMenu,
        onToggleMenu,
        placement,
      }}
    >
      <div ref={dropdownRef} className={`dropdown ${className || ''}`} data-show={showMenu} {...rest} onKeyDown={onKeyDown}>
        {children}
      </div>
    </DropdownContext.Provider>
  );
};

export default Dropdown;
/**
 *
 * @internal
 */
export const useDropdown = () => useContext(DropdownContext) as IDropdownContext;
