import React from 'react';
import {useAccordion} from './accordion';

export interface IAccordionHeaderProps {
  /**
   * Add class to accordion header
   */
  className?: string;
  [others: string]: any;
}

const AccordionHeader: React.FC<IAccordionHeaderProps> = ({children, className, ...rest}) => {
  const {id, showContent, toggleContent} = useAccordion();

  return (
    <button
      className={`accordion-header ${className || ''}`}
      onClick={toggleContent}
      {...rest}
      id={`${id}-header`}
      role="button"
      type="button"
      aria-expanded={showContent}
      aria-controls={`${id}-content`}
    >
      <span className="accordion-title">
        {children}
        <span className="material-icons accordion-icon">{showContent ? 'expand_less' : 'expand_more'}</span>
      </span>
    </button>
  );
};

export default AccordionHeader;
